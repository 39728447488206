import {onBeforeCheckout} from '@/cart/cart-store';
import ButtonLoadingIndicator from '@/components/ButtonLoadingIndicator';
import LoadingIndicator from '@/components/LoadingIndicator';
import {useState} from 'preact/hooks';

function CartDrawerCheckoutButton({
  onCheckout,
  label,
}: {
  onCheckout?: () => void;
  label?: string;
}) {
  const [loadingCheckout, setLoadingCheckout] = useState(false);

  const handleCheckout = (e: Event) => {
    e.preventDefault();
    setLoadingCheckout(true);
    onBeforeCheckout(() => setLoadingCheckout(false));
    onCheckout?.();
  };

  return (
    <ButtonLoadingIndicator
      className="px-6"
      button={
        <button
          className="btn btn-lime btn-square w-full"
          onClick={handleCheckout}
        >
          {<span>{label ?? 'CHECKOUT'}</span>}
        </button>
      }
      fullWidth
      isLoading={loadingCheckout}
      loader={<LoadingIndicator color="logo-lime" />}
    />
  );
}

export default CartDrawerCheckoutButton;
