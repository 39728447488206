import {Cart} from '@/cart/types';
import LineItemDetailLegacy from '../LineItemDetailLegacy';
import PlaceholderLineItem from '@/components/PlaceholderLineItem';

export default function CartDrawerItemList({
  cart,
  locale,
  onQuantityUpdate,
}: {
  cart?: Cart;
  locale?: string;
  onQuantityUpdate?: (line: number, quantity: number) => void;
}) {
  return (
    <div
      data-testid={`line-item-list-view`}
      data-item-count={cart?.item_count ?? 0}
    >
      {cart?.items?.map((item, i) => {
        const line = i + 1;
        const isLoading = item.id <= 0;
        return (
          <>
            {isLoading ? (
              <PlaceholderLineItem key={item.id} />
            ) : (
              <LineItemDetailLegacy
                key={i}
                item={item}
                currency={cart.currency}
                locale={locale}
                onUpdateQuantity={(quantity) =>
                  void onQuantityUpdate?.(line, quantity)
                }
              />
            )}
            {i < cart.items.length - 1 && <hr className="col-span-full" />}
          </>
        );
      })}
    </div>
  );
}
