import {getLocalizedStrings} from '@/localization/localization-store';

export type CartDrawerHeaderProps = {
  title?: string;
  emptyTitle?: string;
  itemCount: number;
};

export default function CartDrawerHeader({
  itemCount,
  title,
  emptyTitle,
}: CartDrawerHeaderProps) {
  const strings = getLocalizedStrings();

  return (
    <p className="px-6 text-xl">
      {itemCount === 0 ? (
        emptyTitle ?? 'Your Cart is Empty'
      ) : (
        <>
          {title ?? 'Your Cart:'}
          <span className="font-bold">
            {' '}
            {`${itemCount} ${itemCount === 1 ? strings.cartItem ?? 'Item' : strings.cartItemPlural ?? 'Items'}`}
          </span>
        </>
      )}
    </p>
  );
}
