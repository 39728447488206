import {getDonationCategory} from '@/cart/cart-store';
import {DonationSelectorOptions} from '@/cart/types';
import heartIcon from '@/icons/heart-icon.svg';

/**
 * This component displays a donation selector.
 * Its text content and options are configurable for different use cases.
 * It exposes an `onUpdate` callback that is called when the user selects
 * a new option.
 * @deprecated Earth Breeze will no longer offer the option to select donation category. All logic related to donation categories should be deprecated from the site.
 */
export default function DonationSelector({
  name = 'donation-category',
  onUpdate,
  settings,
}: DonationSelectorProps) {
  const {
    donation_heading: heading,
    donation_select_label: label,
    donation_select_options: {options},
    donation_terms_conditions: terms,
  } = settings;

  const selectedOption = getDonationCategory() ?? options[0];

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.currentTarget && onUpdate) {
      onUpdate(event.currentTarget.value);
    }
  };

  return (
    <div className="grid-cols-grow-max grid gap-x-6 gap-y-1 bg-bright-mist-white p-4">
      <h6 className="text-base">{heading}</h6>
      <img
        src={heartIcon}
        alt="heart icon"
        height="24"
        width="24"
        className="size-12"
      />
      <label
        className="col-span-full text-sm text-dark-true-black"
        htmlFor="cart-donation-category"
      >
        {label}
      </label>
      <select
        aria-label="Donation category selector"
        className="col-span-full my-1 border border-dark-blue-gray py-2 text-base font-bold text-dark-navy-black"
        id={name}
        name={name}
        onChange={handleChange}
        value={selectedOption}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <p className="col-span-full text-sm text-dark-true-black">{terms}</p>
    </div>
  );
}

type DonationSelectorProps = {
  settings: DonationSelectorSettings;
  name?: string;
  onUpdate?: (value: string) => void;
};

type DonationSelectorSettings = {
  donation_heading: string;
  donation_select_label: string;
  donation_select_options: DonationSelectorOptions;
  donation_terms_conditions: string;
};
