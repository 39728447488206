import {getCart} from '@/cart/cart-store';
import {logError} from '@/utilities/log';
import {useEffect, useState} from 'preact/hooks';

/**
 * Custom hook to determine whether the donation card should be shown.
 *
 * This hook manages the state of whether the donation card should be displayed
 * based on the result of an asynchronous check.
 *
 * @returns - A boolean indicating whether the donation card should be shown.
 *
 * @example
 * const shouldShowDonationCard = useShouldShowDonationCard();
 */
export function useShouldShowDonationCard(): boolean {
  const [show, setShow] = useState(false);

  const updateShow = async (): Promise<void> => {
    try {
      const shouldShow = await getShouldShowDonationCard();
      setShow(shouldShow);
    } catch (error) {
      logError(error);
      setShow(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void updateShow(), [getCart()]);

  return show;
}

async function getShouldShowDonationCard(): Promise<boolean> {
  const res = await fetch(
    window.location.pathname + '?section_id=should-show-donation-card'
  );
  const body = await res.text();
  return body.includes('true');
}
